<template>
  <div class="app-container">
    <div class="wrap">
      <navBar :title="title" @handleGoBack="handleGoBack"></navBar>
      <div class="add-form">
        <van-form validate-first @failed="onFailed" @submit="onSubmit">
          <van-field
              v-model="formInline.supplierName"
              label="供应商名称"
              placeholder="请输入"
              :required="true"
              :rules="[{ required: true, message: '请输入物料名称' }]"
          />

          <van-field
              readonly
              clickable
              v-model="formInline.grade"
              label="供应商等级"
              placeholder="请选择"
              @click="showPickerGrade= true"
              :required="true"
              :rules="[{ required: true, message: '请选择供应商等级' }]"
          />
          <van-popup v-model="showPickerGrade" position="bottom">
            <van-picker
                show-toolbar
                :columns="gradeOptions"
                value-key="dictLabel"
                @confirm="
                onConfirm($event, 'supplierGrade', 'grade', 'dictLabel', 'showPickerGrade')
              "
                @cancel="showPickerGrade = false"
            />
          </van-popup>

          <van-field
              readonly
              clickable
              v-model="formInline.categroyName"
              label="供应商类别"
              placeholder="请选择"
              @click="showPickerCategory= true"
              :required="true"
              :rules="[{ required: true, message: '请选择供应商类别' }]"
          />

          <van-field
              readonly
              clickable
              v-model="formInline.industryName"
              label="所属行业"
              placeholder="请选择"
              @click="showPickerIndustry= true"
              :required="true"
              :rules="[{ required: true, message: '请选择所属行业' }]"
          />

          <van-field
              v-model="formInline.contactPerson"
              label="联系人"
              placeholder="请输入"
          />

          <van-field
              v-model="formInline.department"
              label="部门"
              placeholder="请输入"
          />

          <van-field
              v-model="formInline.position"
              label="职务"
              placeholder="请输入"
          />

          <van-field
              readonly
              clickable
              v-model="formInline.genderStr"
              label="性别"
              placeholder="请选择"
              @click="showPickerGender= true"
              :required="true"
              :rules="[{ required: true, message: '请选择性别' }]"
          />
          <van-popup v-model="showPickerGender" position="bottom">
            <van-picker
                show-toolbar
                :columns="genderOptions"
                value-key="dictLabel"
                @confirm="
                onConfirm($event, 'gender', 'genderStr', 'dictLabel', 'showPickerGender')
              "
                @cancel="showPickerGender = false"
            />
          </van-popup>

          <van-field
              readonly
              clickable
              v-model="formInline.birthday"
              label="生日"
              placeholder="点击选择日期"
              @click="showPickerBirthday = true"
          />
          <van-calendar :min-date="minDate" v-model="showPickerBirthday"
                        @confirm="onConfirmBirthday"/>

          <van-field
              v-model="formInline.nativePlace"
              label="籍贯"
              placeholder="请输入"
          />

          <van-field
              v-model="formInline.phoneNumber"
              label="电话"
              placeholder="请输入"
          />

          <van-field
              v-model="formInline.wechat"
              label="微信"
              placeholder="请输入"
          />

          <van-field
              v-model="formInline.qq"
              label="QQ"
              placeholder="请输入"
          />

          <van-field
              v-model="formInline.email"
              label="邮箱"
              placeholder="请输入"
          />

          <van-field
              v-model="formInline.postcode"
              label="邮编"
              placeholder="请输入"
          />

          <van-field
              v-model="formInline.address"
              label="公司地址"
              placeholder="请输入"
          />

          <van-field
              v-model="formInline.legalRepresentative"
              label="法人代表"
              placeholder="请输入"
          />

          <van-field
              v-model="formInline.registeredCapital"
              label="注册资本"
              placeholder="请输入"
          />

          <van-field
              v-model="formInline.staffSize"
              label="人员规模"
              placeholder="请输入"
          />

          <textAreaInput :remark="formInline.companyProfile" @change="handleChangeCompanyProfile"
                         :title="'公司简介'"/>

          <textAreaInput :remark="formInline.cooperation" @change="handleChangeCooperation" :title="'合作情况'"/>

          <van-field
              readonly
              clickable
              v-model="formInline.fianceName"
              label="开户账户"
              placeholder="请选择"
              @click="showPickerAccount= true"
              :required="true"
              :rules="[{ required: true, message: '请选择开户账户' }]"
          />

          <van-field
              v-model="formInline.billingPeriod"
              label="账期(天)"
              placeholder="请输入"
              type="number"
          />

          <van-field
              v-model="formInline.settlementDate"
              label="几号结算"
              placeholder="请输入"
              type="number"
          />

          <AppendixVue :businessType="uploadParams.businessType" :needUpload="false" v-if="type != 'add'"/>

          <div class="line"></div>
          <van-field name="uploader" label="附件" v-if="type != 'detail' && isShowAuth">
            <template #input>
              <van-uploader v-model="uploader" :after-read="afterRead"/>
            </template>
          </van-field>

          <van-button class="btn" block type="info" native-type="submit" v-if="type != 'detail'">提交
          </van-button>
        </van-form>
      </div>
    </div>

    <SupplierCategory :showPop="showPickerCategory" @handleClose="handleClose"/>
    <Industry :showPop="showPickerIndustry" @handleClose="handleCloseIndustry"/>
    <Account :showDialog="showPickerAccount" @handleClose="handleCloseAccount" @submitList="submitList"/>
  </div>
</template>
<script>
import {addData, selectOne, updateData} from '@/api/purchase/supplier';
import SupplierCategory from '@/components/supplierCategory'
import Industry from '@/components/industry'
import Account from '@/components/account'
import AppendixVue from '@/views/components/crm/Appendix.vue';
import {formatDate} from "@/utils/ruoyi";
import {submiteUpload} from "@/api/system/config";

export default {
  components: {SupplierCategory, Industry, Account, AppendixVue},
  data() {
    return {
      formInline: {},
      title: '新建供应商',
      id: this.$route.query.id,
      type: this.$route.query.type,
      showPickerGrade: false,
      showPickerCategory: false,
      showPickerIndustry: false,
      showPickerGender: false,
      showPickerBirthday: false,
      showPickerAccount: false,
      minDate: new Date(2010, 0, 1),
      //供应商等级
      gradeOptions: [],
      //性别
      genderOptions: [
        {dictValue: 0, dictLabel: '男'},
        {dictValue: 1, dictLabel: '女'}
      ],
      uploader: [],
      uploadParams: {
        businessType: 'supplier',
        businessId: undefined,
        file: null,
      },
      isShowAuth: localStorage.getItem('isShowAuth') === '1'
    };
  },

  mounted() {
    //供应商等级
    this.getDicts('supplier_grade').then((response) => {
      this.gradeOptions = response.data;
    });

    //回显
    this.findDetail()
  },

  methods: {
    handleGoBack() {
      this.$router.replace('/purchase/purchaseSupplier');
      this.$router.go(-1)
    },

    //回显
    async findDetail() {
      if (this.type == 'add') {
        this.formInline = {};
        this.title = '新建供应商';
      } else {
        let itemDetail = await selectOne({id: this.id});
        itemDetail = itemDetail.data
        this.formInline = itemDetail;
        this.formInline.genderStr = this.formInline.gender == 0 ? '男' : '女'
        if (itemDetail && this.type == 'edit') {
          this.title = '编辑供应商';
        } else {
          this.title = '供应商详情';
        }
      }
    },

    //确认
    onConfirm(event, key, keyName, eventKey, visible) {
      this.formInline[key] = event['dictValue'];
      this.formInline[keyName] = event[eventKey];
      this[visible] = false;
    },

    //公司简介
    handleChangeCompanyProfile(val) {
      this.formInline.companyProfile = val;
    },

    //合作情况简介
    handleChangeCooperation(val) {
      this.formInline.cooperation = val;
    },

    //分类关闭
    handleClose(dataList) {
      if (dataList && dataList.length > 0) {
        this.formInline.categroyId = dataList[0].id
        this.formInline.categroyName = dataList[0].name
      }
      this.showPickerCategory = false
    },

    //行业关闭
    handleCloseIndustry(dataList) {
      if (dataList) {
        this.formInline.industryId = dataList[0].id
        this.formInline.industryName = dataList[0].name
      }
      this.showPickerIndustry = false
    },

    //账户关闭
    handleCloseAccount() {
      this.showPickerAccount = false
    },

    //账户提交
    submitList(id, name) {
      this.formInline.fianceId = id;
      this.formInline.fianceName = name;
      this.showPickerAccount = false
    },

    //日期确认
    onConfirmBirthday(value) {
      this.formInline.birthday = formatDate(value, 'yyyy-MM-dd');
      this.showPickerBirthday = false;
    },

    async onSubmit() {
      let data = JSON.stringify(this.formInline)

      const result = this.formInline.id ? await updateData(data) : await addData(data);
      if (result.code == 200) {
        this.$toast.success('操作成功');
        if (this.uploadParams.file) {
          this.uploadParams.businessId = result.data
          setTimeout(async () => {
            await submiteUpload(this.uploadParams);
          }, 1000);
        }
        this.handleGoBack();
      }
    },

    onFailed(errorInfo) {
      console.log('failed', errorInfo);
    },

    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      this.uploadParams.file = file.file;
    },
  },
};
</script>

<style lang="scss" scoped>
.base-tit {
  padding: 20px 30px;
  font-size: 24px;
  color: #666666;
}

.btn {
  width: 710px;
  height: 100px;
  background: #0091ff;
  border-radius: 6px 6px 6px 6px;
  margin: 118px auto 0;
  font-size: 38px;
}

// 导航样式 --start
::v-deep .van-hairline--bottom::after {
  border-bottom: none;
}

::v-deep .van-nav-bar .van-icon {
  color: #000;
}

::v-deep .van-nav-bar__content {
  height: 100px;
  line-height: 100px;
}

::v-deep .van-nav-bar__arrow,
::v-deep .van-nav-bar .van-icon {
  font-size: 32px;
}

// 导航样式 --end
</style>
